// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

// Fonts family
$base-font: 'Signika', sans-serif;


// Colors
$orange-100: rgb(239, 126, 26);
$orange-200: rgb(237, 127, 45);
$orange-300: rgb(236, 124, 21);

$gray-100: rgb(245, 245, 245);
$gray-200: rgb(120, 120, 120);
$gray-300: rgb(119, 119, 119);
$gray-400: rgb(87, 87, 87);
$gray-500: rgb(42, 41, 40);

$white: rgb(255, 255, 255);
$black: rgb(0, 0, 0);
$primary: $orange-200;
$text: $gray-200;


// Custom grid
$grid-gutter-width: 30px;

$grid-breakpoints: (
  xs: 0,
  xm: 480px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1170px
);