// -----------------------------------------------------------------------------
// Style for coursers slider section
// -----------------------------------------------------------------------------

.courses-slider {
  margin: 90px 0 60px 0;
  background-color: rgb(237, 237, 237);
  position: relative;

  @include media-breakpoint-up(md) {
    background-color: transparent;
  }

  &__content {
    padding: 40px 0 30px 0;
    background-color: rgb(237, 237, 237);

    @include media-breakpoint-up(md) {
      padding: 40px 30px 30px 30px;
    }
  }

  &__title {
    margin-bottom: 10px;
    font-weight: bold;
    color: $gray-500;
  }

  &__inner {
    padding-right: 15px;
    padding-left: 15px;
    position: relative;
    overflow: hidden;
    display: none;

    @include media-breakpoint-up(lg) {
      padding-right: 30px;
      padding-left: 30px;
    }

    &.slick-initialized {
      display: block;
    }
  }

  &__item {
    padding: 0 15px;

    &-img {
      width: 100%;
      height: 200px;
      object-fit: cover;
    }

    &-link {
      max-width: 260px;
      margin: 0 auto;
      border-radius: 15px;
      background-color: $white;
      cursor: pointer;
      overflow: hidden;
      position: relative;
      transition: box-shadow 0.2s cubic-bezier(0.22,0.61,0.36,1), transform 0.2s cubic-bezier(0.22,0.61,0.36,1);

      &:hover {
        box-shadow: 0 10px 20px rgba($black, 0.2);
        transform: translateY(-10px);
      }

      @include media-breakpoint-up(md) {
        max-width: none;
      }
    }

    &-top {
      top: 10px;
      right: 10px;
      position: absolute;
    }

    &-tag {
      padding: 8px 12px;
      font-size: 11px;
      font-weight: 600;
      color: $text;
      border-radius: 100px;
      background-color: $gray-100;
    }

    &-caption {
      height: 86px;
      padding: 15px 25px;
      background-image: radial-gradient(circle at right bottom, transparent 19%,rgba(42, 41, 40, 0.06) calc(19% + 1px), transparent calc(19% + 2px)), radial-gradient(circle at right bottom, transparent 37%,rgba(42, 41, 40, 0.05) calc(37% + 1px), transparent calc(37% + 2px)), radial-gradient(circle at right bottom, transparent 55%,rgba(42, 41, 40, 0.04) calc(55% + 1px),transparent calc(55% + 2px)), radial-gradient(circle at right bottom,transparent 73%,rgba(41, 41, 41, 0.03) calc(73% + 1px), transparent calc(73% + 2px));
    }

    &-title {
      font-size: 14px;
      font-weight: bold;
      color: $gray-500;
      line-height: 1.2;
    }
  }

  .slide-arrow {
    width: 44px;
    height: 44px;
    padding: 0;
    border-radius: 50%;
    border: none;
    outline: none;
    background-color: $primary;
    box-shadow: 0 10px 20px rgba(240, 124, 0, 0.2);
    cursor: pointer;
    transition: transform 0.2s cubic-bezier(0.22,0.61,0.36,1);

    &--prev {
      margin-right: 10px;
    }

    &--next {
      margin-left: 10px;
    }

    &:hover {
      transform: scale(1.05);
    }

    &__icon {
      width: 16px;
      height: 16px;
    }
  }

  .slick {
    &-list {
      margin-right: -15px;
      margin-left: -15px;
    }

    &-track {
      padding-top: 30px;
      padding-bottom: 30px;
      display: flex;
    }
  }
}