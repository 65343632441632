// -----------------------------------------------------------------------------
// Style for header
// -----------------------------------------------------------------------------

.header {
  height: 70px;
  top: 0;
  left: 0;
  background-color: $white;
  position: fixed;
  z-index: 1030;
  transition: box-shadow 0.3s ease-in-out;

  &--sticky {
    box-shadow: 0 7px 30px 0 rgba($black, 0.1);
  }

  @include media-breakpoint-up(lg) {
    height: 90px;
  }

  &__logo {
    margin-right: 20px;

    &-img {
      width: 120px;

      @include media-breakpoint-up(xm) {
        width: 140px;
      }

      @include media-breakpoint-up(sm) {
        width: 160px;
      }

      @include media-breakpoint-up(md) {
        width: 180px;
      }

      @include media-breakpoint-up(lg) {
        width: 225px;
      }
    }
  }

  &__link {
    margin-right: 15px;

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      .header {
        &__link {
          &-img {
            filter: grayscale(0);
            opacity: 1;
          }
        }
      }
    }

    @include media-breakpoint-up(md) {
      margin-right: 22px;
    }

    @include media-breakpoint-up(lg) {
      margin-right: 27px;
    }

    &-img {
      height: 22px;
      filter: grayscale(1);
      opacity: 0.4;
      transition: all 0.3s ease-in-out;

      @include media-breakpoint-up(xm) {
        height: 24px;
      }

      @include media-breakpoint-up(sm) {
        height: 28px;
      }

      @include media-breakpoint-up(md) {
        height: 32px;
      }

      @include media-breakpoint-up(lg) {
        height: 37px;
      }
    }
  }

  &__phone {
    &:hover {
      .header {
        &__phone {
          &-number {
            color: $primary;
          }
        }
      }
    }

    @include media-breakpoint-up(xm) {
      margin-left: 15px;
    }

    @include media-breakpoint-up(md) {
      margin-left: 30px;
    }

    @include media-breakpoint-up(lg) {
      margin-left: 44px;
    }

    &-img {
      width: 18px;
      margin-right: 5px;

      @include media-breakpoint-up(sm) {
        width: 21px;
      }

      @include media-breakpoint-up(sm) {
        width: 24px;
      }

      @include media-breakpoint-up(lg) {
        width: 26px;
      }
    }

    &-number {
      font-size: 16px;
      color: $black;
      line-height: 1;
      transition: color 0.3s ease-in-out;

      @include media-breakpoint-up(sm) {
        font-size: 18px;
      }

      @include media-breakpoint-up(lg) {
        font-size: 22px;
      }
    }
  }

  &.sticky {

  }
}