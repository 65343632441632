// -----------------------------------------------------------------------------
// This file contains all @font-face declarations, if any.
// -----------------------------------------------------------------------------

/* Webfont: Signika Light*/
@font-face {
  font-family: 'Signika';
  src: url('../fonts/Signika-Light.ttf') format('truetype');
  font-style: normal;
  font-weight: 300;
  text-rendering: optimizeLegibility;
}

/* Webfont: Signika Regular */
@font-face {
  font-family: 'Signika';
  src: url('../fonts/Signika-Regular.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: Signika Medium */
@font-face {
  font-family: 'Signika';
  src: url('../fonts/Signika-Medium.ttf') format('truetype');
  font-style: normal;
  font-weight: 500;
  text-rendering: optimizeLegibility;
}

/* Webfont: Signika Semibold */
@font-face {
  font-family: 'Signika';
  src: url('../fonts/Signika-SemiBold.ttf') format('truetype');
  font-style: normal;
  font-weight: 600;
  text-rendering: optimizeLegibility;
}

/* Webfont: Signika Bold */
@font-face {
  font-family: 'Signika';
  src: url('../fonts/Signika-Bold.ttf') format('truetype');
  font-style: normal;
  font-weight: bold;
  text-rendering: optimizeLegibility;
}