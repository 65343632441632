// -----------------------------------------------------------------------------
// Styles for video modal
// -----------------------------------------------------------------------------

.video-modal {
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  display: none;
  position: fixed;
  z-index: 1040;

  &__wrapper {
    background-color: rgba(237, 237, 237, 0.9);
    position: relative;
  }

  &__scrollbar {
    height: calc(100% - 100px);
  }

  &__center-box {
    height: 100%;
    display: flex;
  }

  &__content {
    width: 100%;
    max-width: 675px;
    margin: 0 auto;
    padding: 0 30px;
  }

  &__inner {
    width: 100%;
    padding-top: 56.25%;
    overflow: hidden;
    position: relative; 

    .scroll-content {
      display: flex;
      flex-direction: column;
    }

    iframe {
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      position: absolute;
    }
  }

  &__btn {
    top: 15px;
    right: 30px;
    padding: 0;
    border: none;
    background-color: transparent;
    cursor: pointer;
    position: absolute;
    transition: transform 0.3s ease-in-out;

    &:hover {
      transform: rotate(180deg);
    }

    @include media-breakpoint-up(lg) {
      top: 30px;
    }

    &-icon {
      width: 20px;
      height: 20px;

      @include media-breakpoint-up(lg) {
        width: 28px;
        height: 28px;
      }
    }
  }
}