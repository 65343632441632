// -----------------------------------------------------------------------------
// Styles for buttons
// -----------------------------------------------------------------------------

.btn {
  height: 65px;
  padding: 10px 35px;
  border-radius: 15px;
  background-color: $orange-100;
  cursor: pointer;
  overflow: hidden;
  position: relative;

  @include media-breakpoint-up(sm) {
    width: 350px;
    padding: 10px 25px;
  }

  &::before {
    content: "";
    width: 250%;
    height: 500%;
    top: 70%;
    left: 50%;
    background-image: url('../images/school-pattern.png');
    transform: rotate(15deg) translate(-50%, -50%);
    transform-origin: left top;
    opacity: 0.1;
    position: absolute;
    transition: background-position 0.3s ease-in-out;
  }

  &__icon,
  &__text,
  &__arrow {
    position: relative;
    z-index: 1;
  }

  &__icon {
    width: 20px;

    @include media-breakpoint-up(sm) {
      width: 23px;
    }
  }

  &__text {
    margin-left: 15px;
    font-size: 16px;
    font-weight: 600;
    color: $white;
    text-align: left;

    @include media-breakpoint-up(sm) {
      margin-left: 25px;
      font-size: 18px;
    }
  }

  &__arrow {
    width: 16px;
    margin-left: auto;
    transition: transform 0.3s ease-in-out;
  }

  &:hover {
    &::before {
      background-position-x: 15px;
    }

    .btn {
      &__arrow {
        transform: translateX(8px);
      }
    }
  }
}