// -----------------------------------------------------------------------------
// Style for banner at the top of the page
// -----------------------------------------------------------------------------

.banner {
  &__content {
    @include media-breakpoint-down(md) {
      max-width: none;
      padding: 0;
    }
  }

  &__inner {
    height: 550px;
    background: url('../images/programming-courses.jpg') no-repeat center center;
    background-size: cover;

    @include media-breakpoint-up(lg) {
      height: 640px;
    }
  }

  &__top {
    padding: 30px 15px;
    background-color: rgba($white, 0.85);

    @include media-breakpoint-up(lg) {
      padding: 50px 30px 45px 30px;
    }

    &-title {
      font-weight: 500;
      color: $black;
      line-height: 1.1;
    }

    &-text {
      margin-top: 8px;
      font-size: 18px;
      color: $orange-300;
      line-height: 1.2;

      @include media-breakpoint-up(md) {
        font-size: 22px;
      }

      @include media-breakpoint-up(lg) {
        font-size: 26px;
      }
    }
  }

  &__btn {
    margin-bottom: 53px;
    padding: 0 15px;

    @include media-breakpoint-up(sm) {
      padding: 0;
    }
  }

  &__bottom {
    margin-top: -36px;

    &-content {
      width: 100%;
      padding: 15px 0;
      background-color: $white;

      @include media-breakpoint-up(sm) {
        width: auto;
        height: 72px;
        padding: 8px 15px;
        border-radius: 500px;
      }

      @include media-breakpoint-up(lg) {
        padding: 8px 26px;
      }
    }

    &-link {
      padding: 0 15px;

      @include media-breakpoint-up(xm) {
        padding: 0 25px;
      }

      @include media-breakpoint-up(sm) {
        padding: 0 17px;
      }

      &:hover {
        .banner {
          &__bottom {
            &-icon {
              filter: none;
            }

            &-text {
              color: $primary;
            }
          }
        }
      }
    }

    &-icon {
      height: 26px;
      margin: 0 auto 5px auto;
      filter: grayscale(1);
      transition: filter 0.3s ease-in-out;

      @include media-breakpoint-up(sm) {
        margin: 0 10px 0 0;
      }

      @include media-breakpoint-up(md) {
        height: 30px;
      }

      @include media-breakpoint-up(lg) {
        height: 34px;
        margin-right: 15px;
      }
    }

    &-text {
      font-size: 14px;
      color: $text;
      transition: color 0.3s ease-in-out;

      @include media-breakpoint-up(md) {
        font-size: 16px;
      }
    }
  }
}