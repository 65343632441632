// -----------------------------------------------------------------------------
// Style for sections with partners logos
// -----------------------------------------------------------------------------

.partners {
  margin: 75px 0;

  &__logo {
    margin: 0 18px 20px 18px;

    &:first-child {
      margin-left: 0;
    }

    &--1 {
      width: 124px;
    }

    &--2 {
      width: 75px;
    }

    &--3 {
      width: 95px;
    }

    &--4 {
      width: 130px;
    }

    &--5 {
      width: 350px;
    }
  }

  &__heading {
    margin-bottom: 25px;
    font-weight: bold;
    color: $black;
  }

  &__text {
    font-weight: 300;
  }
}