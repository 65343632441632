// -----------------------------------------------------------------------------
// Styles for footer
// -----------------------------------------------------------------------------

.footer {
  margin: 60px 0 50px 0;

  &__logo {
    margin-bottom: 10px;
    
    @include media-breakpoint-up(md) {
      margin-bottom: 0;
    }

    @include media-breakpoint-up(lg) {
      flex-shrink: 0;
    }

    &-img {
      width: 225px;
    }
  }

  &__contact {
    @include media-breakpoint-up(lg) {
      width: 100%;
    }

    @include media-breakpoint-up(xl) {
      margin-top: 15px;
    }

    &-address {
      font-size: 15px;
      color: $text;

      @include media-breakpoint-up(xm) {
        width: 100%;
      }

      @include media-breakpoint-up(md) {
        margin-left: 40px;
        text-align: right;
      }

      @include media-breakpoint-up(xl) {
        width: auto;
      }
    }

    &-link {
      margin-top: 8px;

      &:last-child {
        margin-right: 0;
      }

      @include media-breakpoint-up(xm) {
        margin-top: 10px;
      }

      @include media-breakpoint-up(xm) {
        margin-right: 20px;
      }

      @include media-breakpoint-up(lg) {
        margin-right: 38px;
      }

      @include media-breakpoint-up(xl) {
        margin-top: 0;
      }

      &:hover {
        .footer {
          &__contact {
            &-text {
              color: $primary;
            }
          }
        }
      }
    }

    &-img {
      width: 16px;
      margin-right: 18px;
    }

    &-text {
      font-size: 15px;
      font-weight: 600;
      color: $gray-400;
      transition: color 0.3s ease-in-out;
    }
  }

  &__fb {
    margin-left: auto;
    padding: 8px;

    @include media-breakpoint-up(xm) {
      margin-left: 20px;
      padding-top: 12px;
    }

    @include media-breakpoint-up(lg) {
      margin-left: 50px;
    }

    @include media-breakpoint-up(xl) {
      padding-top: 8px;
      padding-bottom: 12px;
    }

    &:hover {
      .footer {
        &__fb {
          &-img {
            filter: none;
          }
        }
      }
    }

    &-img {
      width: 12px;
      filter: grayscale(100%);
      transition: filter 0.3s ease-in-out;

      @include media-breakpoint-up(xm) {
        width: 10px;
      }
    }
  }
}