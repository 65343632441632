// -----------------------------------------------------------------------------
// This file contains all basic styles
// -----------------------------------------------------------------------------

body {
  min-height: 100vh;
  margin: 70px 0 0 0;
  font-family: $base-font;
  font-size: 16px;
  font-weight: normal;
  color: $text;
  line-height: 1;
  background-color: $white;
  -webkit-font-smoothing: antialiased;
  -webkit-font-smoothing: subpixel-antialiased;

  @include media-breakpoint-up(lg) {
    margin-top: 90px;
  }

  &.no-scroll {
    overflow: hidden !important;
  }
}

a,
button,
.btn {
  font-family: $base-font;
  text-decoration: none;
  
  &:hover {
    text-decoration: none;
  }

  &:focus {
    outline: transparent;
    box-shadow: none;
  }
}

a {
  display: inline-block;
  -webkit-transform: translateZ(0px);
  transition: color 0.3s ease-in-out;

  &:hover {
    color: $primary;
  }
}

ul {
  margin: 0;
  padding: 0;
}

iframe {
  max-width: 100%;
}

img {
  display: block;

  &.aligncenter {
    margin-right: auto;
    margin-left: auto;
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}