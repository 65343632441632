// -----------------------------------------------------------------------------
// Basic typography style for copy text
// -----------------------------------------------------------------------------

p {
  margin: 0;
  margin-bottom: 15px;
  padding: 0;
  font-size: 16px;
  line-height: 1.4;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0 0 15px 0;
  padding: 0;
  font-weight: normal;
  line-height: 1;
}

h1 {
  font-size: 24px;

  @include media-breakpoint-up(sm) {
    font-size: 28px;
  }

  @include media-breakpoint-up(md) {
    font-size: 30px;
  }

  @include media-breakpoint-up(lg) {
    font-size: 36px;
  }

  @include media-breakpoint-up(xl) {
    font-size: 46px;
  }
}

h2 {
  font-size: 24px;

  @include media-breakpoint-up(md) {
    font-size: 28px;
  }

  @include media-breakpoint-up(lg) {
    font-size: 30px;
  }

  @include media-breakpoint-up(xl) {
    font-size: 36px;
  }
}

h3 {
  font-size: 22px;

  @include media-breakpoint-up(lg) {
    font-size: 24px;
  }

  @include media-breakpoint-up(xl) {
    font-size: 26px;
  }
}

h4 {
  font-size: 20px;

  @include media-breakpoint-up(lg) {
    font-size: 24px;
  }
}

h5 {
  font-size: 18px;

  @include media-breakpoint-up(md) {
    font-size: 20px;
  }
}

h6 {
  font-size: 16px;
}