// -----------------------------------------------------------------------------
// Helper classes
// -----------------------------------------------------------------------------

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-lg-left {
  @include media-breakpoint-up(lg) {
    text-align: left;
  }
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.ms-auto {
  margin-left: auto;
}