// -----------------------------------------------------------------------------
// Style for coursers section
// -----------------------------------------------------------------------------

.courses {
  margin: 70px 0 90px  0;

  &__title {
    margin-bottom: 30px;
    font-weight: bold;
    color: $gray-500;
  }

  &__subtitle {
    font-size: 16px;
    color: $gray-500;
    line-height: 1.4;
  }
  
  &__blockquote {
    margin: 40px 0;
    padding-left: 45px;
    position: relative;

    &::before {
      content: "“";
      top: 0;
      left: 0;
      font-size: 60px;
      font-weight: 500;
      color: $primary;
      position: absolute;
    }

    &-text {
      margin-bottom: 20px;
      font-size: 20px;
      font-weight: 500;
      color: $primary;
      line-height: 1.3;
    }

    &-cite {
      font-style: normal;
      color: rgba(73, 88, 101, 0.75);
    }
  }

  &__text {
    margin-bottom: 45px;
    font-size: 15px;
    line-height: 1.5;
  }

  &__video {
    width: 100%;
    height: 280px;
    margin: 0 auto;
    border-radius: 25px;
    cursor: pointer;
    overflow: hidden;
    position: relative;

    &:hover {
      .courses {
        &__video {
          &-bg {
            filter: blur(0.5rem);
            transform: scale(1.1) rotate(-5deg);
          }
        }
      }
    }

    @include media-breakpoint-up(xm) {
      height: 340px;
    }

    @include media-breakpoint-up(lg) {
      height: 420px;
    }

    @include media-breakpoint-up(xl) {
      height: 550px;
    }
    
    &-caption {
      width: 80%;
      top: 50%;
      left: 50%;
      position: absolute;
      z-index: 1;
      transform: translate(-50%, -50%);
    }

    &-text {
      margin-bottom: 30px;
      font-size: 28px;
      font-weight: bold;
      color: $white;
      line-height: 1.2;

      @include media-breakpoint-up(xm) {
        font-size: 32px;
      }

      @include media-breakpoint-up(xl) {
        font-size: 36px;
      }
    }

    &-btn {
      width: 42px;
      height: 42px;
      border-radius: 50%;
      background-color: $primary;
      transition: transform 0.3s ease-in-out;

      &:hover {
        transform: scale(1.05);
      }
    }

    &-icon {
      width: 16px;
      height: 16px;
    }

    &-bg {
      margin-left: auto;
      object-fit: cover;
      overflow: hidden;
      transition: filter 0.3s ease-in-out, transform 0.3s ease-in-out;
    }
  }
}